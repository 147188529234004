<template>
   <div width="100" class="mt-12 mb-12 grad" style="height: 100%">
      <v-row align="center" align-lg="start" justify="center" justify-lg="start">
          <v-col cols="6"  v-show="$vuetify.breakpoint.lgAndUp">
              <!-- <v-layout justify-center class="mt-n2 ml-12 pl-12">
                  <img src="@/assets/signup.svg" height="270px">                 
            </v-layout>
            <v-layout justify-center class="mt-5 mb-5 ml-12 pl-12 " column>
             <v-layout justify-start class=" mb-5 ml-10 pl-10" >
                  <h2 class="primary--text">See why you should register with us!</h2>                
            </v-layout> -->
            <!-- <v-layout justify-center column class="ml-5 pl-12"> -->
             <!-- <v-layout justify-start class="mt-2 mb-2 ml-12 pl-12">
                  <h3 style="font-weight: 500;"><v-icon class="mr-4" color="black" >check_circle</v-icon>Fast and reliable data</h3>                
            </v-layout>
             <v-layout justify-start class="mt-2 mb-2 ml-12 pl-12">
                  <h3 style="font-weight: 500;"><v-icon  class="mr-4" color="black">check_circle</v-icon>Save up on heavy development cost!</h3>                
            </v-layout>
             <v-layout justify-start class="mt-2 mb-2 ml-12 pl-12">
                  <h3 style="font-weight: 500;"><v-icon  class="mr-4" color="black">check_circle</v-icon>Easy to use.</h3> 
            </v-layout>
             <v-layout justify-start class="mt-2 mb-2 ml-12 pl-12">
                  <h3 style="font-weight: 500;"><v-icon  class="mr-4" color="black">check_circle</v-icon>Get detailted documentation</h3>                
            </v-layout>
            </v-layout> -->
            <!-- </v-layout> -->

             <v-layout justify-center class=" mt-12 mr-n12  " column >
                <h1 style="text-align:center;font-size: 28px; font-weight:600">Accelerate Inovation</h1>
                <h1 class="primary--text" style="text-align:center;font-size: 28px;font-weight:600">Reduce Costs</h1>
                  
            </v-layout>

            <v-layout justify-center  class="mt-12 pr-12">
              <div style="width:50%">
            <v-img :src="require('../assets/signup1.svg')"  contain ></v-img>
              </div>
            </v-layout>
                   <p style="font-size: 14px; font-weight:300" class="cancel--text mt-12 ml-12 pl-12 pt-5"><v-icon small color="cancel" left>copyright</v-icon>Copyright 2022. All rights reserved.</p>


          </v-col>
          <v-col cols="11" sm="9" lg="5" :class="$vuetify.breakpoint.lgAndUp? 'mr-n12' : 'ml-5 mr-5'">
                   
       
       <v-card  elevation="0" class="pl-5 pt-3 pb-3 pr-5" style="background: inherit;">
            <v-layout class="mb-5 mt-10" justify-center>
              <h2>Welcome!</h2>
            </v-layout>
           
              <v-form  class="ma-5" ref="form"
              v-model="valid"
                lazy-validation>
                <v-text-field
                  label="Username *"
                  v-model="username"
                  placeholder="Enter username"
                  prepend-icon="account_box"
                  required
                  ref="username"
                  outlined
                  
                  @keyup.enter="$refs.email.focus()"
                  :rules="usernameRules"
                  
                  
                ></v-text-field>
                <v-text-field
                  label="Email *"
                  v-model="email"
                  placeholder="Enter email"
                  prepend-icon="email"
                  required
                  ref="email"
                  outlined
                 
                  @keyup.enter="$refs.password.focus()"
                  :rules="emailRules"

             
                ></v-text-field>
                <v-text-field
                  label="Password *"
                  v-model="password"
                  prepend-icon="lock"
                  placeholder="Enter password"
                  min="8"
                  type="password"
                  ref="password"
                  outlined
                 append-icon="visibility_off"
                  @keyup.enter="$refs.confirmPassword.focus()"
                  required
                  :rules="passwordRules"
                ></v-text-field>
                 <v-text-field
                  label="Confirm Password *"
                  v-model="confirmPassword"
                  prepend-icon="lock"
                  placeholder="Renter password"
                  min="8"
                  type="password"
                  outlined
                  append-icon="visibility_off"
                  ref="confirmPassword"
                  @keyup.enter="signUp"
                  :rules="confirmPasswordRules"
             
                  required
                ></v-text-field>
                <v-layout justify-center>
                  <v-btn   class="ma-5 mb-n4 loginbutton" color="primary" @click="signUp">Sign-Up</v-btn>
                </v-layout>
                 </v-form>

                  <v-layout justify-center>
                  <p   class="mb-5 button" >Have an account already? <span class="primary--text underline button" style="text-decoration: underline" @click="gotoLogin">Login</span></p>
                </v-layout>

       </v-card>
        <!-- <p style="font-size: 14px; font-weight:300" class="cancel--text mt-2 ml-1"> <v-icon small color="cancel" left>copyright</v-icon>Copyright 2022. All rights reserved.</p> -->

           
              
          </v-col>

      </v-row>


  </div>
</template>

<script>
//const lazyImage = () => import('../components/lazyImage.vue')

export default {

  data : () => ({
    valid: false,
    username: '',
    email : '',
    password: '',
    confirmPassword: '',
    usernameRules: [
      v => !!v || "Name is required",
      v => (v && v.length >= 5) || "Name must be more than 5 characters"
    ],
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+/.test(v) || "E-mail must be valid"
    ],
    passwordRules: [
      v => !!v || "Password is required",
      v => (v && v.length > 6) || "Password must be more than 6 characters"
    ],
    confirmPasswordRules: [v => !!v || "Password is required"],

  }),

  // created: function(){
  //   this.$refs.username.focus()

  // },

  //  components:{
  //     lazyImage

  //   },

  methods: {
    signUp: function(){
      if(this.$refs.form.validate()){
      var data = {
        person_name : this.username,
       username: this.username,
       email: this.email,
       password: this.password,
      }

      if(this.confirmPassword == this.password){
        //this.$store.dispatch('login' ,{username: 'admin', password: 'siany-kpcm'})
        this.$store.dispatch('signUp', data)

      }}
    },

    gotoLogin: function(){
      this.$router.push({ name : 'login'})
    }
  }

}
</script>

<style>

.grad {
background-image: linear-gradient(to top, #F1F9FF , #F1F9FF  , #ffffff);
}

.button{
  cursor: pointer;
}

</style>